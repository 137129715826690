@import "../../../brand.scss";

.container {
    border: 1px solid #F1F1F4;
    position: relative;
    border-radius: 0.85rem;
    padding: 35px 2.25rem;
    margin: 1rem 0 1rem 0;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 4px 0px;
}

.with-icon {
    min-height: 14rem;
}

.title-container {
    min-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.backgroundIcon {
    position: absolute;
    right:0;
    top: 0;
    color: hsl(0, 0%, 97%);
    font-size: 12em;
    line-height: 1;
    margin: 1rem;
}

.small {
    padding: 15px 1.5rem;
}