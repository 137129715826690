.fib, .fi {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}

.fi {
    position: relative;
    display: inline-block;
    width: 1.333333em;
    line-height: 1em;
}
.fi:before {
    content: " ";
}
.fi.fis {
    width: 1em;
}


/*.fi-ac {
    background-image: url(./Svgs/flags/4x3/ac.svg);
}
.fi-ac-r {
    background-image: url(./Svgs/flags/1x1/ac.svg);
}*/

.fi-ad {
    background-image: url(./Svgs/flags/4x3/ad.svg);
}
.fi-ad-r {
    background-image: url(./Svgs/flags/1x1/ad.svg);
}

.fi-ae {
    background-image: url(./Svgs/flags/4x3/ae.svg);
}
.fi-ae-r {
    background-image: url(./Svgs/flags/1x1/ae.svg);
}

.fi-af {
    background-image: url(./Svgs/flags/4x3/af.svg);
}
.fi-af-r {
    background-image: url(./Svgs/flags/1x1/af.svg);
}

.fi-ag {
    background-image: url(./Svgs/flags/4x3/ag.svg);
}
.fi-ag-r {
    background-image: url(./Svgs/flags/1x1/ag.svg);
}

.fi-ai {
    background-image: url(./Svgs/flags/4x3/ai.svg);
}
.fi-ai-r {
    background-image: url(./Svgs/flags/1x1/ai.svg);
}

.fi-al {
    background-image: url(./Svgs/flags/4x3/al.svg);
}
.fi-al-r {
    background-image: url(./Svgs/flags/1x1/al.svg);
}

.fi-am {
    background-image: url(./Svgs/flags/4x3/am.svg);
}
.fi-am-r {
    background-image: url(./Svgs/flags/1x1/am.svg);
}

.fi-ao {
    background-image: url(./Svgs/flags/4x3/ao.svg);
}
.fi-ao-r {
    background-image: url(./Svgs/flags/1x1/ao.svg);
}

.fi-aq {
    background-image: url(./Svgs/flags/4x3/aq.svg);
}
.fi-aq-r {
    background-image: url(./Svgs/flags/1x1/aq.svg);
}

.fi-ar {
    background-image: url(./Svgs/flags/4x3/ar.svg);
}
.fi-ar-r {
    background-image: url(./Svgs/flags/1x1/ar.svg);
}

.fi-as {
    background-image: url(./Svgs/flags/4x3/as.svg);
}
.fi-as-r {
    background-image: url(./Svgs/flags/1x1/as.svg);
}

.fi-at {
    background-image: url(./Svgs/flags/4x3/at.svg);
}
.fi-at-r {
    background-image: url(./Svgs/flags/1x1/at.svg);
}

.fi-au {
    background-image: url(./Svgs/flags/4x3/au.svg);
}
.fi-au-r {
    background-image: url(./Svgs/flags/1x1/au.svg);
}

.fi-aw {
    background-image: url(./Svgs/flags/4x3/aw.svg);
}
.fi-aw-r {
    background-image: url(./Svgs/flags/1x1/aw.svg);
}

.fi-ax {
    background-image: url(./Svgs/flags/4x3/ax.svg);
}
.fi-ax-r {
    background-image: url(./Svgs/flags/1x1/ax.svg);
}

.fi-az {
    background-image: url(./Svgs/flags/4x3/az.svg);
}
.fi-az-r {
    background-image: url(./Svgs/flags/1x1/az.svg);
}

.fi-ba {
    background-image: url(./Svgs/flags/4x3/ba.svg);
}
.fi-ba-r {
    background-image: url(./Svgs/flags/1x1/ba.svg);
}

.fi-bb {
    background-image: url(./Svgs/flags/4x3/bb.svg);
}
.fi-bb-r {
    background-image: url(./Svgs/flags/1x1/bb.svg);
}

.fi-bd {
    background-image: url(./Svgs/flags/4x3/bd.svg);
}
.fi-bd-r {
    background-image: url(./Svgs/flags/1x1/bd.svg);
}

.fi-be {
    background-image: url(./Svgs/flags/4x3/be.svg);
}
.fi-be-r {
    background-image: url(./Svgs/flags/1x1/be.svg);
}

.fi-bf {
    background-image: url(./Svgs/flags/4x3/bf.svg);
}
.fi-bf-r {
    background-image: url(./Svgs/flags/1x1/bf.svg);
}

.fi-bg {
    background-image: url(./Svgs/flags/4x3/bg.svg);
}
.fi-bg-r {
    background-image: url(./Svgs/flags/1x1/bg.svg);
}

.fi-bh {
    background-image: url(./Svgs/flags/4x3/bh.svg);
}
.fi-bh-r {
    background-image: url(./Svgs/flags/1x1/bh.svg);
}

.fi-bi {
    background-image: url(./Svgs/flags/4x3/bi.svg);
}
.fi-bi-r {
    background-image: url(./Svgs/flags/1x1/bi.svg);
}

.fi-bj {
    background-image: url(./Svgs/flags/4x3/bj.svg);
}
.fi-bj-r {
    background-image: url(./Svgs/flags/1x1/bj.svg);
}

.fi-bl {
    background-image: url(./Svgs/flags/4x3/bl.svg);
}
.fi-bl-r {
    background-image: url(./Svgs/flags/1x1/bl.svg);
}

.fi-bm {
    background-image: url(./Svgs/flags/4x3/bm.svg);
}
.fi-bm-r {
    background-image: url(./Svgs/flags/1x1/bm.svg);
}

.fi-bn {
    background-image: url(./Svgs/flags/4x3/bn.svg);
}
.fi-bn-r {
    background-image: url(./Svgs/flags/1x1/bn.svg);
}

.fi-bo {
    background-image: url(./Svgs/flags/4x3/bo.svg);
}
.fi-bo-r {
    background-image: url(./Svgs/flags/1x1/bo.svg);
}

.fi-bq {
    background-image: url(./Svgs/flags/4x3/bq.svg);
}
.fi-bq-r {
    background-image: url(./Svgs/flags/1x1/bq.svg);
}

.fi-br {
    background-image: url(./Svgs/flags/4x3/br.svg);
}
.fi-br-r {
    background-image: url(./Svgs/flags/1x1/br.svg);
}

.fi-bs {
    background-image: url(./Svgs/flags/4x3/bs.svg);
}
.fi-bs-r {
    background-image: url(./Svgs/flags/1x1/bs.svg);
}

.fi-bt {
    background-image: url(./Svgs/flags/4x3/bt.svg);
}
.fi-bt-r {
    background-image: url(./Svgs/flags/1x1/bt.svg);
}

.fi-bw {
    background-image: url(./Svgs/flags/4x3/bw.svg);
}
.fi-bw-r {
    background-image: url(./Svgs/flags/1x1/bw.svg);
}

.fi-by {
    background-image: url(./Svgs/flags/4x3/by.svg);
}
.fi-by-r {
    background-image: url(./Svgs/flags/1x1/by.svg);
}

.fi-bz {
    background-image: url(./Svgs/flags/4x3/bz.svg);
}
.fi-bz-r {
    background-image: url(./Svgs/flags/1x1/bz.svg);
}

.fi-ca {
    background-image: url(./Svgs/flags/4x3/ca.svg);
}
.fi-ca-r {
    background-image: url(./Svgs/flags/1x1/ca.svg);
}

.fi-cc {
    background-image: url(./Svgs/flags/4x3/cc.svg);
}
.fi-cc-r {
    background-image: url(./Svgs/flags/1x1/cc.svg);
}

.fi-cd {
    background-image: url(./Svgs/flags/4x3/cd.svg);
}
.fi-cd-r {
    background-image: url(./Svgs/flags/1x1/cd.svg);
}

.fi-cf {
    background-image: url(./Svgs/flags/4x3/cf.svg);
}
.fi-cf-r {
    background-image: url(./Svgs/flags/1x1/cf.svg);
}

.fi-cg {
    background-image: url(./Svgs/flags/4x3/cg.svg);
}
.fi-cg-r {
    background-image: url(./Svgs/flags/1x1/cg.svg);
}

.fi-ch {
    background-image: url(./Svgs/flags/4x3/ch.svg);
}
.fi-ch-r {
    background-image: url(./Svgs/flags/1x1/ch.svg);
}

.fi-ci {
    background-image: url(./Svgs/flags/4x3/ci.svg);
}
.fi-ci-r {
    background-image: url(./Svgs/flags/1x1/ci.svg);
}

.fi-ck {
    background-image: url(./Svgs/flags/4x3/ck.svg);
}
.fi-ck-r {
    background-image: url(./Svgs/flags/1x1/ck.svg);
}

.fi-cl {
    background-image: url(./Svgs/flags/4x3/cl.svg);
}
.fi-cl-r {
    background-image: url(./Svgs/flags/1x1/cl.svg);
}

.fi-cm {
    background-image: url(./Svgs/flags/4x3/cm.svg);
}
.fi-cm-r {
    background-image: url(./Svgs/flags/1x1/cm.svg);
}

.fi-cn {
    background-image: url(./Svgs/flags/4x3/cn.svg);
}
.fi-cn-r {
    background-image: url(./Svgs/flags/1x1/cn.svg);
}

.fi-co {
    background-image: url(./Svgs/flags/4x3/co.svg);
}
.fi-co-r {
    background-image: url(./Svgs/flags/1x1/co.svg);
}

.fi-cr {
    background-image: url(./Svgs/flags/4x3/cr.svg);
}
.fi-cr-r {
    background-image: url(./Svgs/flags/1x1/cr.svg);
}

.fi-cu {
    background-image: url(./Svgs/flags/4x3/cu.svg);
}
.fi-cu-r {
    background-image: url(./Svgs/flags/1x1/cu.svg);
}

.fi-cv {
    background-image: url(./Svgs/flags/4x3/cv.svg);
}
.fi-cv-r {
    background-image: url(./Svgs/flags/1x1/cv.svg);
}

.fi-cw {
    background-image: url(./Svgs/flags/4x3/cw.svg);
}
.fi-cw-r {
    background-image: url(./Svgs/flags/1x1/cw.svg);
}

.fi-cx {
    background-image: url(./Svgs/flags/4x3/cx.svg);
}
.fi-cx-r {
    background-image: url(./Svgs/flags/1x1/cx.svg);
}

.fi-cy {
    background-image: url(./Svgs/flags/4x3/cy.svg);
}
.fi-cy-r {
    background-image: url(./Svgs/flags/1x1/cy.svg);
}

.fi-cz {
    background-image: url(./Svgs/flags/4x3/cz.svg);
}
.fi-cz-r {
    background-image: url(./Svgs/flags/1x1/cz.svg);
}

.fi-de {
    background-image: url(./Svgs/flags/4x3/de.svg);
}
.fi-de-r {
    background-image: url(./Svgs/flags/1x1/de.svg);
}

.fi-dj {
    background-image: url(./Svgs/flags/4x3/dj.svg);
}
.fi-dj-r {
    background-image: url(./Svgs/flags/1x1/dj.svg);
}

.fi-dk {
    background-image: url(./Svgs/flags/4x3/dk.svg);
}
.fi-dk-r {
    background-image: url(./Svgs/flags/1x1/dk.svg);
}

.fi-dm {
    background-image: url(./Svgs/flags/4x3/dm.svg);
}
.fi-dm-r {
    background-image: url(./Svgs/flags/1x1/dm.svg);
}

.fi-do {
    background-image: url(./Svgs/flags/4x3/do.svg);
}
.fi-do-r {
    background-image: url(./Svgs/flags/1x1/do.svg);
}

.fi-dz {
    background-image: url(./Svgs/flags/4x3/dz.svg);
}
.fi-dz-r {
    background-image: url(./Svgs/flags/1x1/dz.svg);
}

.fi-ec {
    background-image: url(./Svgs/flags/4x3/ec.svg);
}
.fi-ec-r {
    background-image: url(./Svgs/flags/1x1/ec.svg);
}

.fi-ee {
    background-image: url(./Svgs/flags/4x3/ee.svg);
}
.fi-ee-r {
    background-image: url(./Svgs/flags/1x1/ee.svg);
}

.fi-eg {
    background-image: url(./Svgs/flags/4x3/eg.svg);
}
.fi-eg-r {
    background-image: url(./Svgs/flags/1x1/eg.svg);
}

.fi-eh {
    background-image: url(./Svgs/flags/4x3/eh.svg);
}
.fi-eh-r {
    background-image: url(./Svgs/flags/1x1/eh.svg);
}

.fi-er {
    background-image: url(./Svgs/flags/4x3/er.svg);
}
.fi-er-r {
    background-image: url(./Svgs/flags/1x1/er.svg);
}

.fi-es {
    background-image: url(./Svgs/flags/4x3/es.svg);
}
.fi-es-r {
    background-image: url(./Svgs/flags/1x1/es.svg);
}

.fi-et {
    background-image: url(./Svgs/flags/4x3/et.svg);
}
.fi-et-r {
    background-image: url(./Svgs/flags/1x1/et.svg);
}

.fi-eu {
    background-image: url(./Svgs/flags/4x3/eu.svg);
}
.fi-eu-r {
    background-image: url(./Svgs/flags/1x1/eu.svg);
}

.fi-fi {
    background-image: url(./Svgs/flags/4x3/fi.svg);
}
.fi-fi-r {
    background-image: url(./Svgs/flags/1x1/fi.svg);
}

.fi-fj {
    background-image: url(./Svgs/flags/4x3/fj.svg);
}
.fi-fj-r {
    background-image: url(./Svgs/flags/1x1/fj.svg);
}

.fi-fk {
    background-image: url(./Svgs/flags/4x3/fk.svg);
}
.fi-fk-r {
    background-image: url(./Svgs/flags/1x1/fk.svg);
}

.fi-fm {
    background-image: url(./Svgs/flags/4x3/fm.svg);
}
.fi-fm-r {
    background-image: url(./Svgs/flags/1x1/fm.svg);
}

.fi-fo {
    background-image: url(./Svgs/flags/4x3/fo.svg);
}
.fi-fo-r {
    background-image: url(./Svgs/flags/1x1/fo.svg);
}

.fi-fr {
    background-image: url(./Svgs/flags/4x3/fr.svg);
}
.fi-fr-r {
    background-image: url(./Svgs/flags/1x1/fr.svg);
}

.fi-ga {
    background-image: url(./Svgs/flags/4x3/ga.svg);
}
.fi-ga-r {
    background-image: url(./Svgs/flags/1x1/ga.svg);
}

.fi-en, .fi-gb {
    background-image: url(./Svgs/flags/4x3/gb.svg);
}
.fi-en-r, .fi-gb-r {
    background-image: url(./Svgs/flags/1x1/gb.svg);
}

.fi-gd {
    background-image: url(./Svgs/flags/4x3/gd.svg);
}
.fi-gd-r {
    background-image: url(./Svgs/flags/1x1/gd.svg);
}

.fi-ge {
    background-image: url(./Svgs/flags/4x3/ge.svg);
}
.fi-ge-r {
    background-image: url(./Svgs/flags/1x1/ge.svg);
}

.fi-gf {
    background-image: url(./Svgs/flags/4x3/gf.svg);
}
.fi-gf-r {
    background-image: url(./Svgs/flags/1x1/gf.svg);
}

.fi-gg {
    background-image: url(./Svgs/flags/4x3/gg.svg);
}
.fi-gg-r {
    background-image: url(./Svgs/flags/1x1/gg.svg);
}

.fi-gh {
    background-image: url(./Svgs/flags/4x3/gh.svg);
}
.fi-gh-r {
    background-image: url(./Svgs/flags/1x1/gh.svg);
}

.fi-gi {
    background-image: url(./Svgs/flags/4x3/gi.svg);
}
.fi-gi-r {
    background-image: url(./Svgs/flags/1x1/gi.svg);
}

.fi-gl {
    background-image: url(./Svgs/flags/4x3/gl.svg);
}
.fi-gl-r {
    background-image: url(./Svgs/flags/1x1/gl.svg);
}

.fi-gm {
    background-image: url(./Svgs/flags/4x3/gm.svg);
}
.fi-gm-r {
    background-image: url(./Svgs/flags/1x1/gm.svg);
}

.fi-gn {
    background-image: url(./Svgs/flags/4x3/gn.svg);
}
.fi-gn-r {
    background-image: url(./Svgs/flags/1x1/gn.svg);
}

.fi-gp {
    background-image: url(./Svgs/flags/4x3/gp.svg);
}
.fi-gp-r {
    background-image: url(./Svgs/flags/1x1/gp.svg);
}

.fi-gq {
    background-image: url(./Svgs/flags/4x3/gq.svg);
}
.fi-gq-r {
    background-image: url(./Svgs/flags/1x1/gq.svg);
}

.fi-gr {
    background-image: url(./Svgs/flags/4x3/gr.svg);
}
.fi-gr-r {
    background-image: url(./Svgs/flags/1x1/gr.svg);
}

.fi-gs {
    background-image: url(./Svgs/flags/4x3/gs.svg);
}
.fi-gs-r {
    background-image: url(./Svgs/flags/1x1/gs.svg);
}

.fi-gt {
    background-image: url(./Svgs/flags/4x3/gt.svg);
}
.fi-gt-r {
    background-image: url(./Svgs/flags/1x1/gt.svg);
}

.fi-gu {
    background-image: url(./Svgs/flags/4x3/gu.svg);
}
.fi-gu-r {
    background-image: url(./Svgs/flags/1x1/gu.svg);
}

.fi-gw {
    background-image: url(./Svgs/flags/4x3/gw.svg);
}
.fi-gw-r {
    background-image: url(./Svgs/flags/1x1/gw.svg);
}

.fi-gy {
    background-image: url(./Svgs/flags/4x3/gy.svg);
}
.fi-gy-r {
    background-image: url(./Svgs/flags/1x1/gy.svg);
}

.fi-hk {
    background-image: url(./Svgs/flags/4x3/hk.svg);
}
.fi-hk-r {
    background-image: url(./Svgs/flags/1x1/hk.svg);
}

.fi-hm {
    background-image: url(./Svgs/flags/4x3/hm.svg);
}
.fi-hm-r {
    background-image: url(./Svgs/flags/1x1/hm.svg);
}

.fi-hn {
    background-image: url(./Svgs/flags/4x3/hn.svg);
}
.fi-hn-r {
    background-image: url(./Svgs/flags/1x1/hn.svg);
}

.fi-hr {
    background-image: url(./Svgs/flags/4x3/hr.svg);
}
.fi-hr-r {
    background-image: url(./Svgs/flags/1x1/hr.svg);
}

.fi-ht {
    background-image: url(./Svgs/flags/4x3/ht.svg);
}
.fi-ht-r {
    background-image: url(./Svgs/flags/1x1/ht.svg);
}

.fi-hu {
    background-image: url(./Svgs/flags/4x3/hu.svg);
}
.fi-hu-r {
    background-image: url(./Svgs/flags/1x1/hu.svg);
}

.fi-id {
    background-image: url(./Svgs/flags/4x3/id.svg);
}
.fi-id-r {
    background-image: url(./Svgs/flags/1x1/id.svg);
}

.fi-ie {
    background-image: url(./Svgs/flags/4x3/ie.svg);
}
.fi-ie-r {
    background-image: url(./Svgs/flags/1x1/ie.svg);
}

.fi-il {
    background-image: url(./Svgs/flags/4x3/il.svg);
}
.fi-il-r {
    background-image: url(./Svgs/flags/1x1/il.svg);
}

.fi-im {
    background-image: url(./Svgs/flags/4x3/im.svg);
}
.fi-im-r {
    background-image: url(./Svgs/flags/1x1/im.svg);
}

.fi-in {
    background-image: url(./Svgs/flags/4x3/in.svg);
}
.fi-in-r {
    background-image: url(./Svgs/flags/1x1/in.svg);
}

.fi-io {
    background-image: url(./Svgs/flags/4x3/io.svg);
}
.fi-io-r {
    background-image: url(./Svgs/flags/1x1/io.svg);
}

.fi-iq {
    background-image: url(./Svgs/flags/4x3/iq.svg);
}
.fi-iq-r {
    background-image: url(./Svgs/flags/1x1/iq.svg);
}

.fi-ir {
    background-image: url(./Svgs/flags/4x3/ir.svg);
}
.fi-ir-r {
    background-image: url(./Svgs/flags/1x1/ir.svg);
}

.fi-is {
    background-image: url(./Svgs/flags/4x3/is.svg);
}
.fi-is-r {
    background-image: url(./Svgs/flags/1x1/is.svg);
}

.fi-it {
    background-image: url(./Svgs/flags/4x3/it.svg);
}
.fi-it-r {
    background-image: url(./Svgs/flags/1x1/it.svg);
}

.fi-je {
    background-image: url(./Svgs/flags/4x3/je.svg);
}
.fi-je-r {
    background-image: url(./Svgs/flags/1x1/je.svg);
}

.fi-jm {
    background-image: url(./Svgs/flags/4x3/jm.svg);
}
.fi-jm-r {
    background-image: url(./Svgs/flags/1x1/jm.svg);
}

.fi-jo {
    background-image: url(./Svgs/flags/4x3/jo.svg);
}
.fi-jo-r {
    background-image: url(./Svgs/flags/1x1/jo.svg);
}

.fi-jp {
    background-image: url(./Svgs/flags/4x3/jp.svg);
}
.fi-jp-r {
    background-image: url(./Svgs/flags/1x1/jp.svg);
}

.fi-ke {
    background-image: url(./Svgs/flags/4x3/ke.svg);
}
.fi-ke-r {
    background-image: url(./Svgs/flags/1x1/ke.svg);
}

.fi-kg {
    background-image: url(./Svgs/flags/4x3/kg.svg);
}
.fi-kg-r {
    background-image: url(./Svgs/flags/1x1/kg.svg);
}

.fi-kh {
    background-image: url(./Svgs/flags/4x3/kh.svg);
}
.fi-kh-r {
    background-image: url(./Svgs/flags/1x1/kh.svg);
}

.fi-ki {
    background-image: url(./Svgs/flags/4x3/ki.svg);
}
.fi-ki-r {
    background-image: url(./Svgs/flags/1x1/ki.svg);
}

.fi-km {
    background-image: url(./Svgs/flags/4x3/km.svg);
}
.fi-km-r {
    background-image: url(./Svgs/flags/1x1/km.svg);
}

.fi-kn {
    background-image: url(./Svgs/flags/4x3/kn.svg);
}
.fi-kn-r {
    background-image: url(./Svgs/flags/1x1/kn.svg);
}

.fi-kp {
    background-image: url(./Svgs/flags/4x3/kp.svg);
}
.fi-kp-r {
    background-image: url(./Svgs/flags/1x1/kp.svg);
}

.fi-kr {
    background-image: url(./Svgs/flags/4x3/kr.svg);
}
.fi-kr-r {
    background-image: url(./Svgs/flags/1x1/kr.svg);
}

.fi-kw {
    background-image: url(./Svgs/flags/4x3/kw.svg);
}
.fi-kw-r {
    background-image: url(./Svgs/flags/1x1/kw.svg);
}

.fi-ky {
    background-image: url(./Svgs/flags/4x3/ky.svg);
}
.fi-ky-r {
    background-image: url(./Svgs/flags/1x1/ky.svg);
}

.fi-kz {
    background-image: url(./Svgs/flags/4x3/kz.svg);
}
.fi-kz-r {
    background-image: url(./Svgs/flags/1x1/kz.svg);
}

.fi-la {
    background-image: url(./Svgs/flags/4x3/la.svg);
}
.fi-la-r {
    background-image: url(./Svgs/flags/1x1/la.svg);
}

.fi-lb {
    background-image: url(./Svgs/flags/4x3/lb.svg);
}
.fi-lb-r {
    background-image: url(./Svgs/flags/1x1/lb.svg);
}

.fi-lc {
    background-image: url(./Svgs/flags/4x3/lc.svg);
}
.fi-lc-r {
    background-image: url(./Svgs/flags/1x1/lc.svg);
}

.fi-li {
    background-image: url(./Svgs/flags/4x3/li.svg);
}
.fi-li-r {
    background-image: url(./Svgs/flags/1x1/li.svg);
}

.fi-lk {
    background-image: url(./Svgs/flags/4x3/lk.svg);
}
.fi-lk-r {
    background-image: url(./Svgs/flags/1x1/lk.svg);
}

.fi-lr {
    background-image: url(./Svgs/flags/4x3/lr.svg);
}
.fi-lr-r {
    background-image: url(./Svgs/flags/1x1/lr.svg);
}

.fi-ls {
    background-image: url(./Svgs/flags/4x3/ls.svg);
}
.fi-ls-r {
    background-image: url(./Svgs/flags/1x1/ls.svg);
}

.fi-lt {
    background-image: url(./Svgs/flags/4x3/lt.svg);
}
.fi-lt-r {
    background-image: url(./Svgs/flags/1x1/lt.svg);
}

.fi-lu {
    background-image: url(./Svgs/flags/4x3/lu.svg);
}
.fi-lu-r {
    background-image: url(./Svgs/flags/1x1/lu.svg);
}

.fi-lv {
    background-image: url(./Svgs/flags/4x3/lv.svg);
}
.fi-lv-r {
    background-image: url(./Svgs/flags/1x1/lv.svg);
}

.fi-ly {
    background-image: url(./Svgs/flags/4x3/ly.svg);
}
.fi-ly-r {
    background-image: url(./Svgs/flags/1x1/ly.svg);
}

.fi-ma {
    background-image: url(./Svgs/flags/4x3/ma.svg);
}
.fi-ma-r {
    background-image: url(./Svgs/flags/1x1/ma.svg);
}

.fi-mc {
    background-image: url(./Svgs/flags/4x3/mc.svg);
}
.fi-mc-r {
    background-image: url(./Svgs/flags/1x1/mc.svg);
}

.fi-md {
    background-image: url(./Svgs/flags/4x3/md.svg);
}
.fi-md-r {
    background-image: url(./Svgs/flags/1x1/md.svg);
}

.fi-me {
    background-image: url(./Svgs/flags/4x3/me.svg);
}
.fi-me-r {
    background-image: url(./Svgs/flags/1x1/me.svg);
}

.fi-mf {
    background-image: url(./Svgs/flags/4x3/mf.svg);
}
.fi-mf-r {
    background-image: url(./Svgs/flags/1x1/mf.svg);
}

.fi-mg {
    background-image: url(./Svgs/flags/4x3/mg.svg);
}
.fi-mg-r {
    background-image: url(./Svgs/flags/1x1/mg.svg);
}

.fi-mh {
    background-image: url(./Svgs/flags/4x3/mh.svg);
}
.fi-mh-r {
    background-image: url(./Svgs/flags/1x1/mh.svg);
}

.fi-mk {
    background-image: url(./Svgs/flags/4x3/mk.svg);
}
.fi-mk-r {
    background-image: url(./Svgs/flags/1x1/mk.svg);
}

.fi-ml {
    background-image: url(./Svgs/flags/4x3/ml.svg);
}
.fi-ml-r {
    background-image: url(./Svgs/flags/1x1/ml.svg);
}

.fi-mm {
    background-image: url(./Svgs/flags/4x3/mm.svg);
}
.fi-mm-r {
    background-image: url(./Svgs/flags/1x1/mm.svg);
}

.fi-mn {
    background-image: url(./Svgs/flags/4x3/mn.svg);
}
.fi-mn-r {
    background-image: url(./Svgs/flags/1x1/mn.svg);
}

.fi-mo {
    background-image: url(./Svgs/flags/4x3/mo.svg);
}
.fi-mo-r {
    background-image: url(./Svgs/flags/1x1/mo.svg);
}

.fi-mp {
    background-image: url(./Svgs/flags/4x3/mp.svg);
}
.fi-mp-r {
    background-image: url(./Svgs/flags/1x1/mp.svg);
}

.fi-mq {
    background-image: url(./Svgs/flags/4x3/mq.svg);
}
.fi-mq-r {
    background-image: url(./Svgs/flags/1x1/mq.svg);
}

.fi-mr {
    background-image: url(./Svgs/flags/4x3/mr.svg);
}
.fi-mr-r {
    background-image: url(./Svgs/flags/1x1/mr.svg);
}

.fi-ms {
    background-image: url(./Svgs/flags/4x3/ms.svg);
}
.fi-ms-r {
    background-image: url(./Svgs/flags/1x1/ms.svg);
}

.fi-mt {
    background-image: url(./Svgs/flags/4x3/mt.svg);
}
.fi-mt-r {
    background-image: url(./Svgs/flags/1x1/mt.svg);
}

.fi-mu {
    background-image: url(./Svgs/flags/4x3/mu.svg);
}
.fi-mu-r {
    background-image: url(./Svgs/flags/1x1/mu.svg);
}

.fi-mv {
    background-image: url(./Svgs/flags/4x3/mv.svg);
}
.fi-mv-r {
    background-image: url(./Svgs/flags/1x1/mv.svg);
}

.fi-mw {
    background-image: url(./Svgs/flags/4x3/mw.svg);
}
.fi-mw-r {
    background-image: url(./Svgs/flags/1x1/mw.svg);
}

.fi-mx {
    background-image: url(./Svgs/flags/4x3/mx.svg);
}
.fi-mx-r {
    background-image: url(./Svgs/flags/1x1/mx.svg);
}

.fi-my {
    background-image: url(./Svgs/flags/4x3/my.svg);
}
.fi-my-r {
    background-image: url(./Svgs/flags/1x1/my.svg);
}

.fi-mz {
    background-image: url(./Svgs/flags/4x3/mz.svg);
}
.fi-mz-r {
    background-image: url(./Svgs/flags/1x1/mz.svg);
}

.fi-na {
    background-image: url(./Svgs/flags/4x3/na.svg);
}
.fi-na-r {
    background-image: url(./Svgs/flags/1x1/na.svg);
}

.fi-nc {
    background-image: url(./Svgs/flags/4x3/nc.svg);
}
.fi-nc-r {
    background-image: url(./Svgs/flags/1x1/nc.svg);
}

.fi-ne {
    background-image: url(./Svgs/flags/4x3/ne.svg);
}
.fi-ne-r {
    background-image: url(./Svgs/flags/1x1/ne.svg);
}

.fi-nf {
    background-image: url(./Svgs/flags/4x3/nf.svg);
}
.fi-nf-r {
    background-image: url(./Svgs/flags/1x1/nf.svg);
}

.fi-ng {
    background-image: url(./Svgs/flags/4x3/ng.svg);
}
.fi-ng-r {
    background-image: url(./Svgs/flags/1x1/ng.svg);
}

.fi-ni {
    background-image: url(./Svgs/flags/4x3/ni.svg);
}
.fi-ni-r {
    background-image: url(./Svgs/flags/1x1/ni.svg);
}

.fi-nl {
    background-image: url(./Svgs/flags/4x3/nl.svg);
}
.fi-nl-r {
    background-image: url(./Svgs/flags/1x1/nl.svg);
}

.fi-no, .fi-bv {
    background-image: url(./Svgs/flags/4x3/no.svg);
}
.fi-no-r, .fi-bv-r {
    background-image: url(./Svgs/flags/1x1/no.svg);
}

.fi-np {
    background-image: url(./Svgs/flags/4x3/np.svg);
}
.fi-np-r {
    background-image: url(./Svgs/flags/1x1/np.svg);
}

.fi-nr {
    background-image: url(./Svgs/flags/4x3/nr.svg);
}
.fi-nr-r {
    background-image: url(./Svgs/flags/1x1/nr.svg);
}

.fi-nu {
    background-image: url(./Svgs/flags/4x3/nu.svg);
}
.fi-nu-r {
    background-image: url(./Svgs/flags/1x1/nu.svg);
}

.fi-nz {
    background-image: url(./Svgs/flags/4x3/nz.svg);
}
.fi-nz-r {
    background-image: url(./Svgs/flags/1x1/nz.svg);
}

.fi-om {
    background-image: url(./Svgs/flags/4x3/om.svg);
}
.fi-om-r {
    background-image: url(./Svgs/flags/1x1/om.svg);
}

.fi-pa {
    background-image: url(./Svgs/flags/4x3/pa.svg);
}
.fi-pa-r {
    background-image: url(./Svgs/flags/1x1/pa.svg);
}

.fi-pe {
    background-image: url(./Svgs/flags/4x3/pe.svg);
}
.fi-pe-r {
    background-image: url(./Svgs/flags/1x1/pe.svg);
}

.fi-pf {
    background-image: url(./Svgs/flags/4x3/pf.svg);
}
.fi-pf-r {
    background-image: url(./Svgs/flags/1x1/pf.svg);
}

.fi-pg {
    background-image: url(./Svgs/flags/4x3/pg.svg);
}
.fi-pg-r {
    background-image: url(./Svgs/flags/1x1/pg.svg);
}

.fi-ph {
    background-image: url(./Svgs/flags/4x3/ph.svg);
}
.fi-ph-r {
    background-image: url(./Svgs/flags/1x1/ph.svg);
}

.fi-pk {
    background-image: url(./Svgs/flags/4x3/pk.svg);
}
.fi-pk-r {
    background-image: url(./Svgs/flags/1x1/pk.svg);
}

.fi-pl {
    background-image: url(./Svgs/flags/4x3/pl.svg);
}
.fi-pl-r {
    background-image: url(./Svgs/flags/1x1/pl.svg);
}

.fi-pm {
    background-image: url(./Svgs/flags/4x3/pm.svg);
}
.fi-pm-r {
    background-image: url(./Svgs/flags/1x1/pm.svg);
}

.fi-pn {
    background-image: url(./Svgs/flags/4x3/pn.svg);
}
.fi-pn-r {
    background-image: url(./Svgs/flags/1x1/pn.svg);
}

.fi-pr {
    background-image: url(./Svgs/flags/4x3/pr.svg);
}
.fi-pr-r {
    background-image: url(./Svgs/flags/1x1/pr.svg);
}

.fi-ps {
    background-image: url(./Svgs/flags/4x3/ps.svg);
}
.fi-ps-r {
    background-image: url(./Svgs/flags/1x1/ps.svg);
}

.fi-pt {
    background-image: url(./Svgs/flags/4x3/pt.svg);
}
.fi-pt-r {
    background-image: url(./Svgs/flags/1x1/pt.svg);
}

.fi-pw {
    background-image: url(./Svgs/flags/4x3/pw.svg);
}
.fi-pw-r {
    background-image: url(./Svgs/flags/1x1/pw.svg);
}

.fi-py {
    background-image: url(./Svgs/flags/4x3/py.svg);
}
.fi-py-r {
    background-image: url(./Svgs/flags/1x1/py.svg);
}

.fi-qa {
    background-image: url(./Svgs/flags/4x3/qa.svg);
}
.fi-qa-r {
    background-image: url(./Svgs/flags/1x1/qa.svg);
}

.fi-re {
    background-image: url(./Svgs/flags/4x3/re.svg);
}
.fi-re-r {
    background-image: url(./Svgs/flags/1x1/re.svg);
}

.fi-ro {
    background-image: url(./Svgs/flags/4x3/ro.svg);
}
.fi-ro-r {
    background-image: url(./Svgs/flags/1x1/ro.svg);
}

.fi-rs {
    background-image: url(./Svgs/flags/4x3/rs.svg);
}
.fi-rs-r {
    background-image: url(./Svgs/flags/1x1/rs.svg);
}

.fi-ru {
    background-image: url(./Svgs/flags/4x3/ru.svg);
}
.fi-ru-r {
    background-image: url(./Svgs/flags/1x1/ru.svg);
}

.fi-rw {
    background-image: url(./Svgs/flags/4x3/rw.svg);
}
.fi-rw-r {
    background-image: url(./Svgs/flags/1x1/rw.svg);
}

.fi-sa {
    background-image: url(./Svgs/flags/4x3/sa.svg);
}
.fi-sa-r {
    background-image: url(./Svgs/flags/1x1/sa.svg);
}

.fi-sb {
    background-image: url(./Svgs/flags/4x3/sb.svg);
}
.fi-sb-r {
    background-image: url(./Svgs/flags/1x1/sb.svg);
}

.fi-sc {
    background-image: url(./Svgs/flags/4x3/sc.svg);
}
.fi-sc-r {
    background-image: url(./Svgs/flags/1x1/sc.svg);
}

.fi-sd {
    background-image: url(./Svgs/flags/4x3/sd.svg);
}
.fi-sd-r {
    background-image: url(./Svgs/flags/1x1/sd.svg);
}

.fi-se {
    background-image: url(./Svgs/flags/4x3/se.svg);
}
.fi-se-r {
    background-image: url(./Svgs/flags/1x1/se.svg);
}

.fi-sg {
    background-image: url(./Svgs/flags/4x3/sg.svg);
}
.fi-sg-r {
    background-image: url(./Svgs/flags/1x1/sg.svg);
}

.fi-sh, .fi-ac {
    background-image: url(./Svgs/flags/4x3/sh.svg);
}
.fi-sh-r, .fi-ac-r {
    background-image: url(./Svgs/flags/1x1/sh.svg);
}

.fi-si {
    background-image: url(./Svgs/flags/4x3/si.svg);
}
.fi-si-r {
    background-image: url(./Svgs/flags/1x1/si.svg);
}

.fi-sj {
    background-image: url(./Svgs/flags/4x3/sj.svg);
}
.fi-sj-r {
    background-image: url(./Svgs/flags/1x1/sj.svg);
}

.fi-sk {
    background-image: url(./Svgs/flags/4x3/sk.svg);
}
.fi-sk-r {
    background-image: url(./Svgs/flags/1x1/sk.svg);
}

.fi-sl {
    background-image: url(./Svgs/flags/4x3/sl.svg);
}
.fi-sl-r {
    background-image: url(./Svgs/flags/1x1/sl.svg);
}

.fi-sm {
    background-image: url(./Svgs/flags/4x3/sm.svg);
}
.fi-sm-r {
    background-image: url(./Svgs/flags/1x1/sm.svg);
}

.fi-sn {
    background-image: url(./Svgs/flags/4x3/sn.svg);
}
.fi-sn-r {
    background-image: url(./Svgs/flags/1x1/sn.svg);
}

.fi-so {
    background-image: url(./Svgs/flags/4x3/so.svg);
}
.fi-so-r {
    background-image: url(./Svgs/flags/1x1/so.svg);
}

.fi-sr {
    background-image: url(./Svgs/flags/4x3/sr.svg);
}
.fi-sr-r {
    background-image: url(./Svgs/flags/1x1/sr.svg);
}

.fi-ss {
    background-image: url(./Svgs/flags/4x3/ss.svg);
}
.fi-ss-r {
    background-image: url(./Svgs/flags/1x1/ss.svg);
}

.fi-st {
    background-image: url(./Svgs/flags/4x3/st.svg);
}
.fi-st-r {
    background-image: url(./Svgs/flags/1x1/st.svg);
}

.fi-sv {
    background-image: url(./Svgs/flags/4x3/sv.svg);
}
.fi-sv-r {
    background-image: url(./Svgs/flags/1x1/sv.svg);
}

.fi-sx {
    background-image: url(./Svgs/flags/4x3/sx.svg);
}
.fi-sx-r {
    background-image: url(./Svgs/flags/1x1/sx.svg);
}

.fi-sy {
    background-image: url(./Svgs/flags/4x3/sy.svg);
}
.fi-sy-r {
    background-image: url(./Svgs/flags/1x1/sy.svg);
}

.fi-sz {
    background-image: url(./Svgs/flags/4x3/sz.svg);
}
.fi-sz-r {
    background-image: url(./Svgs/flags/1x1/sz.svg);
}

.fi-tc {
    background-image: url(./Svgs/flags/4x3/tc.svg);
}
.fi-tc-r {
    background-image: url(./Svgs/flags/1x1/tc.svg);
}

.fi-td {
    background-image: url(./Svgs/flags/4x3/td.svg);
}
.fi-td-r {
    background-image: url(./Svgs/flags/1x1/td.svg);
}

.fi-tf {
    background-image: url(./Svgs/flags/4x3/tf.svg);
}
.fi-tf-r {
    background-image: url(./Svgs/flags/1x1/tf.svg);
}

.fi-tg {
    background-image: url(./Svgs/flags/4x3/tg.svg);
}
.fi-tg-r {
    background-image: url(./Svgs/flags/1x1/tg.svg);
}

.fi-th {
    background-image: url(./Svgs/flags/4x3/th.svg);
}
.fi-th-r {
    background-image: url(./Svgs/flags/1x1/th.svg);
}

.fi-tj {
    background-image: url(./Svgs/flags/4x3/tj.svg);
}
.fi-tj-r {
    background-image: url(./Svgs/flags/1x1/tj.svg);
}

.fi-tk {
    background-image: url(./Svgs/flags/4x3/tk.svg);
}
.fi-tk-r {
    background-image: url(./Svgs/flags/1x1/tk.svg);
}

.fi-tl {
    background-image: url(./Svgs/flags/4x3/tl.svg);
}
.fi-tl-r {
    background-image: url(./Svgs/flags/1x1/tl.svg);
}

.fi-tm {
    background-image: url(./Svgs/flags/4x3/tm.svg);
}
.fi-tm-r {
    background-image: url(./Svgs/flags/1x1/tm.svg);
}

.fi-tn {
    background-image: url(./Svgs/flags/4x3/tn.svg);
}
.fi-tn-r {
    background-image: url(./Svgs/flags/1x1/tn.svg);
}

.fi-to {
    background-image: url(./Svgs/flags/4x3/to.svg);
}
.fi-to-r {
    background-image: url(./Svgs/flags/1x1/to.svg);
}

.fi-tr {
    background-image: url(./Svgs/flags/4x3/tr.svg);
}
.fi-tr-r {
    background-image: url(./Svgs/flags/1x1/tr.svg);
}

.fi-tt {
    background-image: url(./Svgs/flags/4x3/tt.svg);
}
.fi-tt-r {
    background-image: url(./Svgs/flags/1x1/tt.svg);
}

.fi-tv {
    background-image: url(./Svgs/flags/4x3/tv.svg);
}
.fi-tv-r {
    background-image: url(./Svgs/flags/1x1/tv.svg);
}

.fi-tw {
    background-image: url(./Svgs/flags/4x3/tw.svg);
}
.fi-tw-r {
    background-image: url(./Svgs/flags/1x1/tw.svg);
}

.fi-tz {
    background-image: url(./Svgs/flags/4x3/tz.svg);
}
.fi-tz-r {
    background-image: url(./Svgs/flags/1x1/tz.svg);
}

.fi-ua {
    background-image: url(./Svgs/flags/4x3/ua.svg);
}
.fi-ua-r {
    background-image: url(./Svgs/flags/1x1/ua.svg);
}

.fi-ug {
    background-image: url(./Svgs/flags/4x3/ug.svg);
}
.fi-ug-r {
    background-image: url(./Svgs/flags/1x1/ug.svg);
}

.fi-um {
    background-image: url(./Svgs/flags/4x3/um.svg);
}
.fi-um-r {
    background-image: url(./Svgs/flags/1x1/um.svg);
}

.fi-us {
    background-image: url(./Svgs/flags/4x3/us.svg);
}
.fi-us-r {
    background-image: url(./Svgs/flags/1x1/us.svg);
}

.fi-uy {
    background-image: url(./Svgs/flags/4x3/uy.svg);
}
.fi-uy-r {
    background-image: url(./Svgs/flags/1x1/uy.svg);
}

.fi-uz {
    background-image: url(./Svgs/flags/4x3/uz.svg);
}
.fi-uz-r {
    background-image: url(./Svgs/flags/1x1/uz.svg);
}

.fi-va {
    background-image: url(./Svgs/flags/4x3/va.svg);
}
.fi-va-r {
    background-image: url(./Svgs/flags/1x1/va.svg);
}

.fi-vc {
    background-image: url(./Svgs/flags/4x3/vc.svg);
}
.fi-vc-r {
    background-image: url(./Svgs/flags/1x1/vc.svg);
}

.fi-ve {
    background-image: url(./Svgs/flags/4x3/ve.svg);
}
.fi-ve-r {
    background-image: url(./Svgs/flags/1x1/ve.svg);
}

.fi-vg {
    background-image: url(./Svgs/flags/4x3/vg.svg);
}
.fi-vg-r {
    background-image: url(./Svgs/flags/1x1/vg.svg);
}

.fi-vi {
    background-image: url(./Svgs/flags/4x3/vi.svg);
}
.fi-vi-r {
    background-image: url(./Svgs/flags/1x1/vi.svg);
}

.fi-vn {
    background-image: url(./Svgs/flags/4x3/vn.svg);
}
.fi-vn-r {
    background-image: url(./Svgs/flags/1x1/vn.svg);
}

.fi-vu {
    background-image: url(./Svgs/flags/4x3/vu.svg);
}
.fi-vu-r {
    background-image: url(./Svgs/flags/1x1/vu.svg);
}

.fi-wf {
    background-image: url(./Svgs/flags/4x3/wf.svg);
}
.fi-wf-r {
    background-image: url(./Svgs/flags/1x1/wf.svg);
}

.fi-ws {
    background-image: url(./Svgs/flags/4x3/ws.svg);
}
.fi-ws-r {
    background-image: url(./Svgs/flags/1x1/ws.svg);
}

.fi-xk {
    background-image: url(./Svgs/flags/4x3/xk.svg);
}
.fi-xk-r {
    background-image: url(./Svgs/flags/1x1/xk.svg);
}

.fi-ye {
    background-image: url(./Svgs/flags/4x3/ye.svg);
}
.fi-ye-r {
    background-image: url(./Svgs/flags/1x1/ye.svg);
}

.fi-yt {
    background-image: url(./Svgs/flags/4x3/yt.svg);
}
.fi-yt-r {
    background-image: url(./Svgs/flags/1x1/yt.svg);
}

.fi-za {
    background-image: url(./Svgs/flags/4x3/za.svg);
}
.fi-za-r {
    background-image: url(./Svgs/flags/1x1/za.svg);
}

.fi-zm {
    background-image: url(./Svgs/flags/4x3/zm.svg);
}
.fi-zm-r {
    background-image: url(./Svgs/flags/1x1/zm.svg);
}

.fi-zw {
    background-image: url(./Svgs/flags/4x3/zw.svg);
}
.fi-zw-r {
    background-image: url(./Svgs/flags/1x1/zw.svg);
}

