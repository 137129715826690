@import "../../../brand.scss";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0, .3);
    z-index: 9999;  
}

.container {
    border-radius: 0.85rem;
    padding: 35px;
    padding-bottom: 100px;
    height: 90vh;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 4px 0px;
}