@import '../../../brand.scss';

.queue_card {
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 5px; 
  background-color: white; 
  cursor: default; 
  transition: background-color 150ms ease;

  &:hover {
    background-color: $gray-200; 
    cursor: pointer; 
    transition-delay: 250ms; 
  }

  .icon_badge {
    min-width: 50px;
    height: 50px; 
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px; 
    position: relative;

    .badge {
      position: absolute;
      top: -10px;
      right: -10px;
      background-color: $primary;
      color: $primary-light;
      border-radius: 50%;
      padding: 5px 10px;
      font-size: 9px;
    }

    i {
      font-size: 30px;
      font-weight: bold;
      color: $light-light;
    }
  }


  .queue_info {
    
    flex-grow: 1;  
    height: 65px;
  }

  .queue_errors {
    display: flex;
    align-items: center;
    i {
      color: $danger;
      font-size: 20px;
      padding-left: 5px;

    }
  }

  .queue_favorite i {
    cursor: pointer;
    font-size: 20px;
    z-index: 8888;
  }

  .fa_warning {
    color: $danger;
    margin-left: 5px;
  }

}

.details_info {
    &:hover {
      background-color: $gray-200; 
      cursor: pointer; 
      transition-delay: 250ms; 
    }
}