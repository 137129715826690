@import "../../../brand.scss";

.presetDateRange {
    opacity: .7;
    transition: opacity .5s
}

.presetDateRange:hover {
    opacity: 1;
    color: $primary !important
}

.label {
    color: $gray-900 !important;
    font-weight: 500 !important;
    font-size: 1.05rem;
}

.label.mandatory::after {
    content: "*";
    position: relative;
    font-size: inherit;
    color: var(--bs-danger);
    padding-left: 0.25rem;
    font-weight: 600;
}

.calendar-container {
    border-radius: .75rem;
    border: 1px solid #ced4da;
    background-color: #fff;
    z-index: 9999;
}