.hover {
    opacity: .7;
    transition: opacity .5s
}

.hover:hover {
    opacity: 1
}

.underlineHover:hover {
    text-decoration: underline;
}

.underlineAlways {
    text-decoration: underline;
}

.disabled {
    opacity: .3;
    cursor: default
}