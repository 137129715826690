@import "../../../brand.scss";

.label {
    font-size: .85rem;
}

.value {
    font-size: .85rem;
    word-break: break-all;
}

.hover:hover, .label.selected, .value.selected {
    color: $primary;
}