@import "../../../brand.scss";

.label {
    color: $gray-900 !important;
    font-weight: 500 !important;
    font-size: 1.05rem;
}

.label.mandatory::after {
    content: "*";
    position: relative;
    font-size: inherit;
    color: var(--bs-danger);
    padding-left: 0.25rem;
    font-weight: 600;
}

.input {
    border-radius: .75rem !important;
    padding: 0.775rem 1rem !important;
    height: 45px;
    font-size: 1.1rem !important;
    color: #4b5675 !important;
}

.input.solid {
    background-color: $gray-100;
    border-color: $gray-100;
    color: $gray-700;
}

.input.solid:focus {
    background-color: $gray-200;
    border-color: $gray-200;
    box-shadow: none !important;
}

.input:focus {
    box-shadow: none !important;
    border-color: #ced4da !important;
}

.input.icon {
    padding-left: 3.25rem !important;
}

@for $i from 1 through 9 {
    .input.withbuttons-#{$i} {
        padding-right: calc(1.65*1rem + 1.9*$i * 1rem) !important;
        font-weight: 400 !important;
    }
}

.button > i {
    color: $gray-600;
    font-size: 1.5rem !important;
}

.button > i:hover {
    color: $primary;
}

.buttons {
    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
    padding-top: 9px;
    padding-bottom: 7px;
    padding-right: 13px;
    padding-left: 7px;
}

