@import "../../../brand.scss";

.label {
    padding: .65rem 1.25rem !important;
    font-weight: 500 !important;
    font-size: 1.075rem !important;
    line-height: 1.5;
    color: $gray-800;
    border-radius: .75rem !important;
}

.container .label {
    width: 100%;
}

.container:hover {
    background-color: inherit !important;

    .label {
        color: $primary;
        background-color: $gray-100;
    } 
}
    