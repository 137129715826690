@import "./brand.scss";
//@import "./cs-font-icons.scss";
@import "./cs-flags.css";

$min-contrast-ratio: 2.5;

/*@font-face {
    font-family: "Sora";
    src: local("SoraBold"),
    url("./fonts/Sora/Sora-Bold.ttf") format("truetype");
    font-weight: bold;
    }

@font-face {
    font-family: "Sora";
    src: local("Sora"),
    url("./fonts/Sora/Sora-Regular.ttf") format("truetype");
    font-weight: normal;
    }

@font-face {
    font-family: "Raleway";
    src: local("RalewayBold"),
    url("./fonts/Raleway/Raleway-Bold.ttf") format("truetype");
    font-weight: bold;
    }

@font-face {
    font-family: "Raleway";
    src: local("Raleway"),
    url("./fonts/Raleway/Raleway-Regular.ttf") format("truetype");
    font-weight: normal;
    }*/

@font-face {
    font-family: 'Inter';
    src: url('./fonts/Inter/Inter-VariableFont_slnt,wght.ttf') format('truetype');
    font-weight: 100 900;
}

html {
    font-size: 13px;
    min-height: 100%;
    height: 100%;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

html::-webkit-scrollbar {
    display: none;
}

body { 
    padding-top: 50px !important; 
    background-color: $cs-white-gray !important;
    color: #071437 !important;
}

/* chrome, edge, firefox, opera scrollbar */
div, main, ol, pre, span, ul {
    scrollbar-width: thin;
    scrollbar-color: $gray-200 transparent;
}
div:hover, main:hover, ol:hover, pre:hover, span:hover, ul:hover {
    scrollbar-color: $gray-300 transparent;
}

/* chrome, edge, safari, opera scrollbar */
::-webkit-scrollbar {
    width: 6px !important;
    color: $gray-200 !important;
}
::-webkit-scrollbar-corner, ::-webkit-scrollbar-track {
    background-color: transparent !important;
}
::-webkit-scrollbar-thumb {
    background-color: $gray-200 !important;
    border-radius: 5px !important;
}
::-webkit-scrollbar-thumb:hover {
    background-color: $gray-300 !important;
}

h1, h2, h3, h4, h5, h6, th {
    font-family: Inter, Helvetica, sans-serif;
}

h1 {
    font-size: 1.35rem !important;
    font-weight: 600 !important;
    margin-bottom: 0px !important;
}

h2 {
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    line-height: 1.2 !important;
    color: #071437 !important;
}

td, a, span, div {
    font-family: Inter, Helvetica, sans-serif;
}

a {
    color: #071437 !important;
}

.text-inverse-white, 
.text-inverse-secondary, 
.text-inverse-light {
    color: $gray-800 !important;
}

.text-inverse-primary, 
.text-inverse-success, 
.text-inverse-warning, 
.text-inverse-danger,
.text-inverse-info,
.text-inverse-dark {
    color: $white !important;
}

@each $gray, $value in $grays {
    .text-#{$gray} {
        color: $value;
    }
}

@each $color-light, $value in $colors-light {
    .text-#{$color-light} {
        color: $value;
    }
    .bg-#{$color-light} {
        background-color: $value;
    }
}

@for $i from 1 through 9 {
    .fw-#{$i}00 {
        font-weight: $i*100 !important;
    }
}

input.form-control::placeholder {
    color: $gray-500 !important;
}

.btn {
    padding: calc(0.775rem + 1px) calc(1.5rem + 1px) !important;
    border-radius: .75rem !important;
}

.btn.btn-sm {
    padding: 0.375rem 0.75rem !important;
}

.btn.btn-outline {
    border: 1px solid #DBDFE9 !important;
}

.btn.btn-outline:hover {
    color: $primary !important;
}

.smaller {
    font-size: 0.8125em;
}

.smallest {
    font-size: 0.75em;
}

.cs-title {
    font-family: Inter, Helvetica, sans-serif;
}

.cs-title-bold {
    font-family: Inter, Helvetica, sans-serif;
}

.cs-text {
    font-family: Inter, Helvetica, sans-serif;
}

.cs-text-bold {
    font-family: Inter, Helvetica, sans-serif;
}

// border
.cs-breadcrumbs-container {
    border-bottom: 1px solid $cs-muted;
}

// background
.cs-bg-white {
    background-color: #fff;
}

.cs-text-gray-800 {
    color: $gray-800;
}

.cs-input-icon {
    position: absolute;
    height: 24px;
    left: 14px;
    top: 10px;
    z-index: 10;
    border-radius: 0.75em;
    font-size: 16px !important;
    color: #99A1B7 !important;
}

.cs-input-icon-clenable {
    position: absolute;
    right: 17px;
    top: 10px;
    z-index: 10;
    border-radius: 0.75em;
    font-size: 16px !important;
    color: #99A1B7 !important;
}

.cs-search-input {
    border-radius: .75em !important;
    height: 45px !important;
    font-size: 1.1rem !important;
    font-weight: 500 !important;
    line-height: 1.5 !important;
    color: #4B5675 !important;
    border-color: #F1F1F4 !important;
}

.cs-search-input:focus {
    box-shadow: none !important;
    border-color: #F1F1F4 !important;
}

.cs-search-input::placeholder {
    color: #99A1B7 !important;
    font-style: normal !important; 
}

.cs-text-icon {
    padding-left: 3.25rem !important;
}

.cs-sidebar-footer {
    position: fixed;
    bottom: 0;
    padding-left: 2.95rem;
    padding-bottom: 1.15rem;
    color: $gray-800;
}

.cs-sidebar-item-open {
    transition: transform 0.2s ease-in-out;
    transform: rotate(180deg);
}

.cs-sidebar-item-close {
    transition: transform 0.2s ease-in-out;
    transform: rotate(0deg);
}

div[aria-labelledby='cs-header-user-dropdown'] {
    width: 100%;
    margin-top: 10px !important
}

div[aria-labelledby='cs-header-language-dropdown'] {
    min-width: 16px !important;
    margin-top: 10px !important
}

.cs-login-environment {
    position: relative;
    top:-25px;
}

.cs-breadcrumb-link {
    text-decoration: none;
    font-size: 0.95rem !important;
    font-weight: 500;
}

.cs-breadcrumb-link:hover {
    color: $primary !important;
}

.Toastify__toast-body {
    font-family: Inter, Helvetica, sans-serif;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: #198754 !important
}
.Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: #dc3545 !important
}

// override react-datepicker
.react-datepicker__day--in-range {
    background-color: $primary !important;
}

.react-datepicker__triangle {
    display:none !important;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    background-color: $gray-400 !important;
    color: $cs-white;
}

.react-datepicker__day--keyboard-selected {
    color: $cs-white !important;
}

.react-datepicker__close-icon::after {
    font-family: 'bootstrap-icons' !important;
    background-color: $cs-muted !important;
    content: "\f62a" !important;
    font-size: 11px !important;
    padding: 5px !important;


}

.cs-react-tree-select .dropdown-trigger {
    border-radius: 0.25rem;
    background-color: hsl(0, 0%, 100%) !important;
}

.cs-react-tree-select span.placeholder {
    background-color: hsl(0, 0%, 100%) !important;
    color: hsl(0, 0%, 50%);
    font-family: Inter, Helvetica, sans-serif;
    cursor: pointer !important;
    opacity: 1;
}

.react-dropdown-tree-select .dropdown,  .react-dropdown-tree-select .dropdown-trigger{
    min-width: 100%;
}

.react-dropdown-tree-select li.tag-item {
    margin: 0px 4px
}

.react-dropdown-tree-select li.tag-item > span.tag {
    border-radius: 2px;
    color: hsl(0, 0%, 20%);
    padding: 3px;
    padding-left: 6px;
    font-size: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: hsl(0, 0%, 90%);

    .tag-remove {
        font-size: 100%;
        font-weight: bold;
        color: hsl(0deg, 0%, 20%);
        padding-left: 8px;
        padding-right: 8px;
    }
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after,
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
    content: none !important;
}

.cs-react-tree-select .dropdown-trigger > span:after {
    font-size: 12px;
    color: #555;
}

.cs-react-tree-select .toggle {
    font: normal normal normal 1.2em FontAwesome;
    color: #555;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.cs-react-tree-select .root {
    padding: 0px;
    margin: 0px;
}

.react-checkbox-tree label:hover {
    background-color: #f9f9f9 !important;
}

.rct-options {
    display: flex !important;
    flex-direction: column !important;
}

div.react-datepicker-popper {
    z-index: 9999;
}

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1350px,
    xxxl: 1700px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1286px,
    xxxl: 1632px
);

$grid-columns: 24;

%loading-skeleton {
    color: transparent;
    appearance: none;
    -webkit-appearance: none;
    background-color: #eee;
    border-color: #eee;

    &::placeholder {
        color: transparent;
    }
}
@keyframes loading-skeleton {
    from {
        opacity: .2;
    }
    to {
        opacity: 0.8;
    }
}

.loading-skeleton {
    pointer-events: none;
    animation: loading-skeleton .7s infinite alternate;

    img {
        filter: grayscale(100) contrast(0%) brightness(1.8);
    }
    h1, h2, h3, h4, h5, h6,
    p, li,
    .btn,
    label,
    .form-control {
        @extend %loading-skeleton;
    }
}

.cs-link {
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
}

.cs-link:hover {
    color: $primary !important;
}

[data-helper-link] {
    text-decoration: underline;
}

[data-helper-link]:after {
    font-family: 'bootstrap-icons' !important;
    content: "\f470" !important;
    font-size: 14px !important;
    padding: 5px !important;
    color: $primary;
    text-decoration: none;
    display: inline-block;
}

[data-helper-link]:hover {
    cursor: pointer;
}

div.fade.tooltip {
    z-index: 10000 !important;
}


.cs-tabs {
    border-bottom: 0px !important;
}

.cs-tabs > li > button,
.cs-tabs > div > a   {
    color: #99A1B7;
    font-size: 1.25rem !important;
    border: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 1rem !important;
    margin-right: 1rem !important;
}

.cs-tabs > li > button:hover,
.cs-tabs > div > a:hover {
    border-bottom: 2px solid $primary !important;
}

.cs-tabs > li > button.active,
.cs-tabs > div > a.active  {
    color: $primary !important;
    border-bottom: 2px solid $primary !important;
}

.form-control:disabled {
    background-color: hsl(0, 0%, 95%) !important;
}

.control-label {
    color: $gray-900 !important;
    font-weight: 500 !important;
    font-size: 1.05rem;
}

.control-label.mandatory::after {
    content: "*";
    position: relative;
    font-size: inherit;
    color: var(--bs-danger);
    padding-left: 0.25rem;
    font-weight: 600;
}

.font-0-5x {
    font-size: 0.5em !important;
}
.font-1-5x {
    font-size: 1.5em !important;
}

.font-2x {
    font-size: 2em !important;
}

.font-3x {
    font-size: 3em !important;
}

.font-4x {
    font-size: 4em !important;
}

.font-5x {
    font-size: 5em !important;
}

.nav-item > div.dropdown-menu {
    border-radius: .75rem !important;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !important;
    border: 0px !important;
    width: 275px !important;
    padding: 1rem 0 !important;
}

.btn.btn-sm.btn-icon {
    padding: 0px !important;
    height: 35px !important;
    width: 35px !important;
    line-height: 1.3 !important;
}

ul.nav.nav-tabs li.nav-item button.nav-link {
    margin: 0 1rem !important;
}

ul.nav.nav-tabs li.nav-item:first-child button.nav-link {
    margin-left: 0 !important; 
}

.mt-field {
    margin-top: 2rem !important;
}

/* modal */
div.modal-content {
    border: 0px solid rgba(0,0,0,.175);
    border-radius: .75rem !important;
    z-index: 9996;
    margin-top: 100px !important;
}

div.modal-header {
    padding: 1.75rem !important;
    border-bottom: 1px solid $gray-200;
}

h4.modal-title {
    line-height: 1.2;
    margin-bottom: .5rem !important;
    font-weight: 600 !important;
    color: $gray-900 !important;
}

div.modal-body {
    margin-left: 3.75rem !important;
    margin-right: 3.75rem !important;
    margin-top: 1.75rem !important;
    margin-bottom: 1.75rem !important;
}

div.modal-footer {
    margin-left: 3.75rem !important;
    margin-right: 3.75rem !important;
    margin-bottom: 1.75rem !important;
    border: 0;
}

.swal2-container{ 
    z-index: 10000 !important;
}

.order-item-backdrop-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001; 
    background: rgba(0, 0, 0, 0.3);
}

@for $i from 1 through 20 {
    .w-#{5 * $i} {
        width: #{5 * $i + "%"} !important;
    }
}

@for $i from 1 through 20 {
    .h-#{5 * $i} {
        height: #{5 * $i + "%"} !important;
    }
}

@import "~bootstrap/scss/bootstrap.scss";


