@import "../../../brand.scss";

.container {
    padding: 0rem 0rem 0rem .75rem;
    margin: 0rem 1.25rem 0 1.2rem !important;
}

.container.selected {
    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 4px 0px;
    border-radius: .75rem;
}

.bullet {
    padding: 0.65rem 1.5rem;
    margin-left: .5rem;
}

.bullet.selected {
    color: $primary;
}

.bullet:before {
    content: "\2022";
    color: $gray-400;
    font-size: 1rem !important;
    line-height: 1.5rem;
    font-weight: 400; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1.2em; /* Also needed for space (tweak if needed) */
    margin-left: -1.5em; /* Also needed for space (tweak if needed) */
}

.bullet.selected:before {
    color: $primary;
    content: "\2022";
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1.2em; /* Also needed for space (tweak if needed) */
    margin-left: -1.5em; /* Also needed for space (tweak if needed) */
}