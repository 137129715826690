@import '../../../brand.scss';

.timelineElement {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-bottom: 1.7rem;
}

.timelineElementDateTime {
    width: 75px;
    position: relative;
}

.timelineElementDate {
    font-size: 1.075rem !important;
    font-weight: 600 !important;
}

.timelineElementTime {
    font-size: 1.075rem !important;
    font-weight: 400 !important;
}

.timelineElementBadge {
    position: relative;
    z-index: 1;
    width: .75rem;
    height: .75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    margin-left: 8px;
    padding: 3px !important;
    border: 6px solid $white !important;
    
}

.timelineElementBadge i {
    font-size: 1.5rem !important;
    font-weight: 900 !important; 
}

.timelineElementContent {
    margin-left: 5px;
}