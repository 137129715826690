@import "../../../brand.scss";

.container {
    border: 1px dashed $gray-300;
    position: relative;
    border-radius: 0.85rem;
    padding: 2rem 1.5rem;
    margin: 1rem 0 1rem 0;
}

.with-icon {
    min-height: 14rem;
}

.title-container {
    min-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.backgroundIcon {
    position: absolute;
    right:0;
    top: 0;
    color: hsl(0, 0%, 97%);
    font-size: 12em;
    line-height: 1;
    margin: 1rem;
}

.title {
    font-size: 1.25rem;
    font-weight: 600;
}

.small {
    padding: 15px 1.5rem;
}