@import "../../../brand.scss";

.filtersContainer {
    border-radius: .85em;
    border:1px solid #F1F1F4;
    background-color: transparent;
    -webkit-transition: max-height 1s, background-color .5s; 
    -moz-transition: max-height 1s, background-color .5s;  
    -ms-transition: max-height 1s, background-color .5s;  
    -o-transition: max-height 1s, background-color .5s;  
    transition: max-height 1s, background-color .5s; 
    max-height: 100px;
    min-height: 50px;
    width:100%;
    padding: 2rem 2.25rem;
    margin-top: 20px;
}

.filtersContainer.open {
    max-height: 800px;
    background-color: transparent;
}

.description {
    opacity: 1;
    -webkit-transition: opacity .5s, max-height .3s; 
    -moz-transition: opacity .5s, max-height .3s; 
    -ms-transition: opacity .5s, max-height .3s; 
    -o-transition: opacity .5s, max-height .3s; 
    transition: opacity .5s, max-height .3s;
    max-height:80px;
    color: #071437;
}
.description.close {
    opacity: 0;
    max-height: 0;
}

.content {
    opacity: 1;
    max-height: 800px;
    -webkit-transition: opacity .5s, max-height .3s; 
    -moz-transition: opacity .5s, max-height .3s; 
    -ms-transition: opacity .5s, max-height .3s; 
    -o-transition: opacity .5s, max-height .3s; 
    transition: opacity .5s, max-height .3s;
}

.content.close {
    opacity: 0;
    max-height: 0;
}

.presetItem {
    opacity: .7;
    transition: opacity .5s
}

.presetItem:hover {
    opacity: 1;
    color: $primary;
}