@import '../../../brand.scss';

.switch > input {
    width: 3.25rem !important;
    height: 1.75rem !important;
    border-radius: 3.25rem !important;
}

.switch > label {
    color: $gray-500;
    display: inline-block;
    font-size: 1.075rem !important;
    line-height: 1.5 !important;
    font-weight: 400 !important;
    margin-top: 0.3rem !important;
    margin-left: .75rem !important;
}

.switch {
    min-height: 1.5rem !important;
    margin-bottom: .125rem !important;
    font-size: 1.075rem !important;
    display: flex;
    align-items: center !important;
}