@import '../../../brand.scss';

.timeline {
    position: relative;
}

.timeline:before {
    content: "";
    position: absolute;
    left: 90px;
    width: 3px;
    top: 0;
    bottom: 0;
    background-color: $gray-200;
}