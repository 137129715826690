// CS
$cs-white: #eff4fa;
$cs-muted: #6c757d;
$cs-white-gray: #F9F9F9;

$blue:      #0d6efd !default;
$indigo:    #6610f2 !default;
$purple:    #6f42c1 !default;
$pink:      #d63384 !default;
$red:       #dc3545 !default;
$light-red: #ffeef3 !default;
$orange:    #fd7e14 !default;
$yellow:    #ffc107 !default;
$green:     #198754 !default;
$teal:      #20c997 !default;
$cyan:      #0dcaf0 !default;
$black:     #000000 !default;
$white:     #ffffff !default;
$gray:      #78829D !default;
$gray-dark: #252F4A !default;
$gray-100:  #f9f9f9 !default;
$gray-200:  #f1f1f4 !default;
$gray-300:  #dbdfe9 !default;
$gray-400:  #c4cada !default;
$gray-500:  #99a1b7 !default;
$gray-600:  #78829d !default;
$gray-700:  #4B5675 !default;
$gray-800:  #252F4A !default;
$gray-900:  #071437 !default;

$grays: (
    "gray-100": $gray-100,
    "gray-200": $gray-200,
    "gray-300": $gray-300,
    "gray-400": $gray-400,
    "gray-500": $gray-500,
    "gray-600": $gray-600,
    "gray-700": $gray-700,
    "gray-800": $gray-800,
    "gray-900": $gray-900,
);

// replicate bootstrap default
$primary:       #1b84ff     !default;
$secondary:     $gray-200     !default;
$success:       #17C653     !default;
$info:          #7239EA     !default;
$warning:       #F6C000     !default;
$danger:        #F8285A     !default;
$light:         $gray-100     !default;
$dark:          #1E2129     !default;

$primary-light:     #E9F3FF !default;
$secondary-light:   #F9F9F9 !default;
$success-light:     #DFFFEA !default;
$info-light:        #F8F5FF !default;
$warning-light:     #FFF8DD !default;
$danger-light:      #FFEEF3 !default;
$dark-light:        #F9F9F9 !default;
$light-light:       #ffffff !default;

$colors-light: (
    "primary-light":    $primary-light,
    "secondary-light":  $secondary-light,
    "success-light":    $success-light,
    "info-light":       $info-light,
    "warning-light":    $warning-light,
    "danger-light":     $danger-light,
    "dark-light":       $dark-light,
    "light-light":      $light-light,
);

$text-muted: $gray-500 !default;

$badge-font-size: .85rem !default;
$table-hover-bg: $gray-100 !default;

//does not work with colors containing alpha
@function encodecolor($string) {
	@if type-of($string) == 'color' {
        $hex: str-slice(ie-hex-str($string), 4);
        $string:unquote("#{$hex}");
    }
    $string: '%23' + $string;
	@return $string;
}

:export {
    black: $black;
    white: $white;

    csWhite: $cs-white;
    csMuted: $cs-muted;

    bPrimary: $primary;
    bSecondary: $secondary;
    bSuccess: $success;
    bInfo: $info;
    bWarning: $warning;
    bDanger: $danger;
    bLight: $light;
    bDark: $dark;

    bPrimaryLight: $primary-light;
    bSecondaryLight: $secondary-light;
    bSuccessLight: $success-light;
    bInfoLight: $info-light;
    bWarningLight: $warning-light;
    bDangerLight: $danger-light;
    bDarkLight: $dark-light;
    bLightLight: $light-light;

    bGray: $gray;
    bGrayDark: $gray-dark;

    bGray100: $gray-100;
    bGray200: $gray-200;
    bGray300: $gray-300;
    bGray400: $gray-400;
    bGray500: $gray-500;
    bGray600: $gray-600;
    bGray700: $gray-700;
    bGray800: $gray-800;
    bGray900: $gray-900;
}