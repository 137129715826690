
.disabled-flags {
    opacity: .5;
}

.hover-flags {
    opacity: .4;
    transition: opacity .5s;
}

.hover-flags:hover {
    opacity: 1;
}
