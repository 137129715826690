@import '../../../brand.scss';

.module-container {
    cursor: pointer;
}

.container {
    opacity: 0.7;
    cursor: pointer;
    border-bottom: 1px solid #F1F1F4;
}

.container-loading {
    opacity: 0.7;
    height: 50px;
    border-bottom: 1px solid $gray-300;
}

.container:hover {
    opacity: 1;
}