@import '../../../brand.scss';

.container {
    padding: 0rem 0 0rem .75rem;
    margin: 0.27rem 1.75rem 0.27rem 1.2rem !important;
}

.link {
    padding: 0.37rem 1rem;
    text-decoration: none;
}

.icon {
    font-size: 1.5rem !important;
    width: 26px;
    margin-right: .5rem;
}

.container.selected {
    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 4px 0px;
    border-radius: .75rem;
}