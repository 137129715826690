@import "../../../brand.scss";

.select {
    font-size: 1.1rem !important;
    color: #4b5675 !important;
    border-radius: .75rem;
    background-image: none;
    height: 45px;
    padding: 0.775rem 2rem 0.775rem 1rem !important;
    padding-right: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 150px;
    cursor: pointer;
}

.select:focus {
    box-shadow: none !important;
    border-color: #ced4da !important;
}

.select-wrapper {
    position: relative;
}

.select-wrapper:after {
    font-family: 'bootstrap-icons';
    content: "\f282";
    position: absolute;
    top: 25%;
    height: 25%;
    right: 7px;
}

.select-dark > select {
    background-color: $dark;
    color: $cs-white;
}

.select-dark:after {
    color: $cs-white;
}