.container {
    padding: 0.15rem 0 .15rem .75rem;
    margin: 0rem 1.75rem 0 1.2rem !important;
}

.link {
    padding: 0.65rem 1rem;
}

.icon {
    font-size: 1.5rem !important;
    width: 26px;
    margin-right: .5rem;
}