@import "../../../brand.scss";

.shipmentId, .link, .copy {
    cursor: pointer;
}

.shipmentId:hover {
    text-decoration: underline;
}

.link:hover {
    text-decoration: underline;
}

.copy:hover {
    text-decoration: underline;
}

$bootstrap-icon-font-family: 'bootstrap-icons';
$bootstrap-icon-font-weight: bolder;
$bootstrap-icon-font-style: bold;
$bootstrap-icon-text-decoration: none;
$bootstrap-icon-font-display: block;
$bootstrap-icon-display: inline-block;
$bootstrap-icon-line-height: 1em;
$bootstrap-icon-font-size: 0.7em;
$bootstrap-icon-margin-left: 0.7em;

@mixin bootstrap-icon($content) {
    font-family: $bootstrap-icon-font-family;
    content: $content;
    font-weight: $bootstrap-icon-font-weight;
    font-style: $bootstrap-icon-font-style;
    text-decoration: $bootstrap-icon-text-decoration;
    font-display: $bootstrap-icon-font-display;
    display: $bootstrap-icon-display;
    line-height: $bootstrap-icon-line-height;
    font-size: $bootstrap-icon-font-size;
    margin-left: $bootstrap-icon-margin-left;
}

.shipmentId:hover::after {
    @include bootstrap-icon("\f360");
}

.copy:hover::after {
    @include bootstrap-icon("\f759");
}

.link:hover::after {
    @include bootstrap-icon("\f470");
}






