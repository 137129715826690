@import "../../../brand.scss";

.navdropdown {
    font-size: 45px;
}

.navdropdown a::after {
    border: 0;
}

.mainName {
    font-size: 1.15rem !important;
    font-weight: 600;
    line-height: 1.5;
}

.username {
    font-size: .95rem !important;
    font-weight: 500;
    line-height: 1.5;
}

.separator {
    border-top:1px solid  $gray-200;
}