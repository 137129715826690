@import "../../../brand.scss";

  .label {
    color: $gray-900 !important;
    font-weight: 500 !important;
    font-size: 1.05rem;
}

.label.mandatory::after {
    content: "*";
    position: relative;
    font-size: inherit;
    color: var(--bs-danger);
    padding-left: 0.25rem;
    font-weight: 600;
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; 
  height: 100vw;
  margin-left: calc((-100vw + 100%) / 2);
  margin-right: calc((-100vw + 100%) / 2);
  background: rgba(250,250,250, 0);
  z-index: 25;  
}

.tree-container {
  width: 100%;
  position: absolute;
  top: 80px;
  left: 0;
  border-radius: .75rem;
  border: 1px solid hsl(0, 0%, 80%);
  z-index: 999;
  background-color: #fff;
  overflow: auto;
  max-height: 300px;
}

.button {
  height: 45px;
  border-radius: .75rem !important;
}

.button:hover {
  border-color: hsl(0, 0%, 70%);
}

.button:focus {
  box-shadow: none !important;
  border-color: #ced4da !important;
}

.treeview-button {
  position: absolute;
  top: 13px;
  right: 20px;
  bottom: 0px;
  font-size: 15px;
  z-index:1000;
}