@import "../../../brand.scss";

.label {
    color: $gray-900 !important;
    font-weight: 500 !important;
    font-size: 1.05rem;
}

.label.mandatory::after {
    content: "*";
    position: relative;
    font-size: inherit;
    color: var(--bs-danger);
    padding-left: 0.25rem;
    font-weight: 600;
}


.input {
    border-left-width: 0;
    border-radius: 0 .75rem .75rem 0;
}

.input:focus {
    box-shadow: none !important;
    border-color: #ced4da !important;
}